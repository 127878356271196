import http from '@/libs/http'

export default class DocumentService {
  getDocuments (offset = 0, limit = 10, search = '') {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/documentos/paginate?limit=${limit}&offset=${offset}&search=${search}`, {
      headers: {
        loading: true
      }
    })
  }

  getDocumentAll () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/documentos`)
  }

  saveDocument (document) {
    return http.post(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/documentos`, document, {
      headers: {
        loading: true
      }
    })
  }

  updateDocument (id, document) {
    return http.put(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/documentos/${id}`, document, {
      headers: {
        loading: true
      }
    })
  }

  deleteDocument (id) {
    return http.delete(`${process.env.VUE_APP_MICROSERVICE_API_LICITACIONES}/documentos/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
