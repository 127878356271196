<template>
  <div class="flex justify-between mt-5 mb-3">
    <Button label="Atras"
            icon="pi pi-arrow-left"
            class="mr-2 p-button-danger"
            @click="$router.go(-1)"
    />
    <Button label="Guardar" icon="pi pi-save" type="submit" form="formLici"/>
  </div>
  <Accordion :multiple="true" :activeIndex="[0,1]">
    <AccordionTab header="Licitacion">
      <div class="grid lg:grid-cols-2 my-2 gap-4">
        <!--  formulario  -->
        <div class="bg-white p-4 rounded-3xl">
          <div class="form-demo">
            <div class="flex justify-content-center">
              <div class="card w-full">
                <b class="text-center mb-5">
                  Licitacion
                </b>
                <form id="formLici" class="p-fluid" @submit.prevent="handleSubmit(!v$.$invalid)">
                  <div class="field">
                    <div class="p-float-label">
                      <InputText id="nombre"
                                 v-model="v$.nombre.$model"
                                 :class="{'p-invalid':v$.nombre.$invalid && submitted}"/>
                      <label for="nombre"
                             :class="{'p-error':v$.nombre.$invalid && submitted}">
                        Nombre*
                      </label>
                    </div>
                    <small v-if="(v$.nombre.$invalid && submitted) || v$.nombre.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.nombre.required.$message = "Nombre es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="">
                      <label for="fechaApertura"
                             :class="{'p-error':v$.fechaApertura.$invalid && submitted}">
                        Fecha Apertura*
                      </label>
                      <InputText id="fechaApertura"
                                 type="date"
                                 v-model="v$.fechaApertura.$model"
                                 :class="{'p-invalid':v$.fechaApertura.$invalid && submitted}"/>
                    </div>
                    <small v-if="(v$.fechaApertura.$invalid && submitted) || v$.fechaApertura.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.nombre.required.$message = "Fecha de apertura es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="">
                      <label for="fechaCierre"
                             :class="{'p-error':v$.fechaCierre.$invalid && submitted}">
                        Fecha Cierre*
                      </label>
                      <InputText id="fechaCierre"
                                 type="date"
                                 v-model="v$.fechaCierre.$model"
                                 :class="{'p-invalid':v$.fechaCierre.$invalid && submitted}"/>
                    </div>
                    <small v-if="(v$.fechaCierre.$invalid && submitted) || v$.fechaCierre.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.nombre.required.$message = "Fecha de cierre es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="p-float-label">
                      <InputText id="comentario"
                                 v-model="v$.comentario.$model"
                                 :class="{'p-invalid':v$.comentario.$invalid && submitted}"/>
                      <label for="comentario"
                             :class="{'p-error':v$.comentario.$invalid && submitted}">
                        Comentario*
                      </label>
                    </div>
                    <small v-if="(v$.comentario.$invalid && submitted) || v$.comentario.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.comentario.required.$message = "Comentario es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="p-float-label">
                      <InputText id="lugarEjecucion"
                                 v-model="v$.lugarEjecucion.$model"
                                 :class="{'p-invalid':v$.lugarEjecucion.$invalid && submitted}"/>
                      <label for="lugarEjecucion"
                             :class="{'p-error':v$.lugarEjecucion.$invalid && submitted}">
                        Lugar Ejecucion*
                      </label>
                    </div>
                    <small v-if="(v$.lugarEjecucion.$invalid && submitted) || v$.lugarEjecucion.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.comentario.required.$message = "Lugar de ejecucion es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="p-float-label">
                      <Dropdown id="tipoRadicacion" v-model="v$.tipoRadicacion.$model" :options="tipoRadicaciones" optionValue="id" :class="{'p-invalid':v$.tipoRadicacion.$invalid && submitted}" optionLabel="name" />
                      <label for="tipoRadicacion"
                             :class="{'p-error':v$.tipoRadicacion.$invalid && submitted}">
                        Tipo Radicacion*
                      </label>
                    </div>
                    <small v-if="(v$.tipoRadicacion.$invalid && submitted) || v$.tipoRadicacion.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.comentario.required.$message = "Tipo de radicacion es requerido" }}
                    </small>
                  </div>

                  <div class="field">
                    <div class="">
                      <label for="adjuntoPliegos">
                        Adjunto Pliegos*
                      </label>
                      <input type="file"
                             id="adjuntoPliegos"
                             accept="application/pdf"
                             @change="onUpload($event.target)"
                             :class="{'p-inputtext': true}"
                             multiple
                      />
                    </div>
                  </div>

                  <div class="field">
                    <div class="p-float-label">
                      <InputNumber id="valorPresupuesto" v-model="v$.valorPresupuesto.$model" mode="currency" currency="COP" locale="es-CO" :class="{'p-invalid':v$.valorPresupuesto.$invalid && submitted}"/>
                      <label for="valorPresupuesto"
                             :class="{'p-error':v$.valorPresupuesto.$invalid && submitted}">
                        Valor Presupuesto*
                      </label>
                    </div>
                    <small v-if="(v$.valorPresupuesto.$invalid && submitted) || v$.valorPresupuesto.$pending.$response"
                           class="p-error"
                    >
                      {{ v$.valorPresupuesto.required.$message = "El valor del presupuesto es requerido" }}
                    </small>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- carpetas   -->
        <div class="bg-white p-4 rounded-3xl">
          <div class="card">
            <div class="flex justify-between mb-5">
              <b class="text-center">
                Carpetas
              </b>
              <Button icon="pi pi-plus" class="p-button-rounded" @click="modalAddFolder=true"/>
            </div>
            <DataTable :value="carpetas"
                       :reorderableColumns="true"
                       @columnReorder="onColReorder"
                       @rowReorder="onRowReorder"
                       responsiveLayout="scroll"
            >
              <template #empty>
                No hay datos para mostrar
              </template>
              <Column :rowReorder="true"
                      headerStyle="width: 3rem"
                      :reorderableColumn="false"
              />
              <Column field="name" header="Nombre"></Column>
              <Column header="Acciones" headerStyle="width: 20%">
                <template #body="{data}">
                  <div class="flex justify-around">
                    <Button icon="pi pi-pencil"
                            class="mr-2"
                            style="height: 40px; width: 40px;"
                            @click="actionModalNameFolder(data)"
                            v-tooltip.top="'Editar Carpeta'"
                    />
                    <Button icon="pi pi-trash"
                            class="mr-2 p-button-danger"
                            style="height: 40px; width: 40px;"
                            @click="eliminarCarpeta(data)"
                            v-tooltip.top="'Eliminar Carpeta'"
                    />
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </AccordionTab>
    <AccordionTab header="Documentos">
      <div class="bg-white rounded-3xl mt-2">
        <InputText type="text" v-model="filterText" placeholder="Filtro Documentos" class="mt-4 ml-4" @keyup="filterDocuments"/>
        <div class="grid grid-cols-2 gap-4">
          <div><!-- Primera Columna-->
            <div class="p-col-12">
              <Divider align="left" type="dashed">
                <b>Documentos</b>
              </Divider>
              <template v-if="documentsFiltered.length < 1">
                <span class="ml-1">No hay documentos para mostrar</span>
              </template>
              <draggable
                class="flex flex-col overflow-x-auto"
                :list="documentsFiltered"
                group="people"
                itemKey="name"
                @change="log"
                style="height: 750px"
              >
                <template #item="{ element }">
                  <div class="ml-1">
                    <Tag icon="pi pi-file-pdf"
                         :value="element.nombre"
                         class="bg-white text-blue-900 cursor-pointer"
                    />
                  </div>
                </template>
              </draggable>
            </div>
          </div>
          <div class="container p-col-12" style="height: 800px; overflow-x: auto"> <!-- Segunda Columna-->
            <Accordion multiple @tab-open="abrio" @tab-close="cerro">
              <AccordionTab v-for="(carpeta, index) of carpetas" :key="index">
                <template #header>
                  <i class="pi pi-folder" :id="`myAcordion_${index}`" />
                  <span class="ml-2">{{ carpeta.name }}</span>
                </template>
                <draggable
                  class="list-group overflow-x-auto"
                  :list="carpeta.list"
                  group="people"
                  itemKey="name"
                  @change="log"
                >
                  <template #item="{ element, index }">
                    <div class="list-group-item">
                      <Tag icon="pi pi-file-pdf"
                           :value="(index+1) + ' - ' + element.nombre"
                           class="bg-white text-blue-900 my-1 cursor-pointer"
                      />
                    </div>
                  </template>
                </draggable>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </div>
    </AccordionTab>
  </Accordion>

  <Dialog v-model:visible="modalAddFolder" :style="{width: '450px'}" header="Agregar Documento" :modal="true" class="p-fluid">
    <div class="field">
      <label for="name">Nombre</label>
      <InputText id="name"
                 v-model.trim="nameFolder"
                 required="true"
                 autofocus
                 class="p-inputtext-sm p-mt-1"
                 :class="{'p-invalid': validationInput(submitted,nameFolder)}"/>
      <small class="p-error"
             v-if="!submitted && !nameFolder"
      >
        Nombre es requerido
      </small>
    </div>
    <template #footer>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="hideModalAddFolder"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class=""
        @click="saveModalAddFolder"
      />
    </template>
  </Dialog>

  <Dialog v-model:visible="modalNameFolder" :modal="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" header="Editar Carpeta">
    <div class="flex flex-col justify-center">
      <span>Nombre Carpeta</span>
      <InputText v-model="updateCarpeta.nombre" />
    </div>
    <template #footer>
      <Button label="No" @click="actionModalNameFolder"/>
      <Button class="p-button-success" label="Actualizar" @click="editarCarpeta" autofocus/>
    </template>
  </Dialog>

  <Toast/>
</template>
<script>
import { ref, onMounted, reactive } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import draggable from 'vuedraggable'
import Tag from 'primevue/tag'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import DocumentService from '../../services/DocumentService'
import LicitacionesService from '../../services/LicitacionesService'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'

export default {
  name: 'CrearLicitacion',
  components: {
    draggable,
    Tag,
    Accordion,
    AccordionTab
  },
  setup () {
    const updateCarpeta = ref({})
    const modalNameFolder = ref(false)
    const router = useRouter()
    const filterText = ref('')
    const documents = ref([])
    const documentsFiltered = ref([])
    onMounted(() => {
      countries.value = []
      _DocumentService.value.getDocumentAll().then(({ data }) => {
        documents.value = data
        documentsFiltered.value = data
      })
    })
    const toast = useToast()
    const _DocumentService = ref(new DocumentService())
    const _LicitacionesService = ref(new LicitacionesService())
    const state = reactive({
      nombre: '',
      fechaApertura: '',
      fechaCierre: '',
      comentario: '',
      lugarEjecucion: '',
      tipoRadicacion: '',
      valorPresupuesto: 0
    })
    const rules = {
      // eslint-disable-next-line
      nombre: {required},
      fechaApertura: { required },
      fechaCierre: { required },
      comentario: { required },
      lugarEjecucion: { required },
      tipoRadicacion: { required },
      valorPresupuesto: { required }
    }
    const submitted = ref(false)
    const countries = ref()
    const date = ref()
    const country = ref()
    const columns = ref([
      { field: 'name', header: 'Nombre' }
    ])
    const modalAddFolder = ref(false)
    const nameFolder = ref('')
    const carpetas = ref([])
    const v$ = useVuelidate(rules, state)
    const tipoRadicaciones = [
      { id: '0', name: 'Fisico' },
      { id: '1', name: 'Virtual' }
    ]
    let formData = new FormData()

    /** methods */
    const filterDocuments = () => {
      console.log('filter', filterText.value)
      if (filterText.value) {
        documentsFiltered.value = documents.value.filter(document => document.nombre.toLowerCase().includes(filterText.value.toLowerCase()))
      } else {
        documentsFiltered.value = documents.value
      }
    }

    const hideModalAddFolder = () => {
      modalAddFolder.value = false
    }
    const validationInput = (a, b) => {
      return a && !b
    }
    const saveModalAddFolder = () => {
      if (nameFolder.value) {
        carpetas.value.push({
          name: nameFolder.value,
          list: []
        })
        nameFolder.value = ''
        modalAddFolder.value = false
      }
    }
    const handleSubmit = (isFormValid) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      if (state.valorPresupuesto <= 0) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'El valor del presupuesto debe ser mayor a 0', life: 3000 })
        return
      }
      if (carpetas.value.length === 0) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Debe tener al menos una carpeta', life: 3000 })
        return
      }
      for (const i in carpetas.value) {
        if (carpetas.value[i].list.length === 0) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'La carpeta debe tener al menos un item', life: 3000 })
          return
        }
      }
      formData.append('nombre', state.nombre)
      formData.append('fechaApertura', state.fechaApertura)
      formData.append('fechaCierre', state.fechaCierre)
      formData.append('comentarios', state.comentario)
      formData.append('lugarEjecucion', state.lugarEjecucion)
      formData.append('tipoRadicacion', state.tipoRadicacion)
      formData.append('valorPresupuesto', state.valorPresupuesto)

      for (const [key, item] of Object.entries(carpetas.value)) {
        console.log({ key, item })
        formData.append('Carpetas[' + key + '][nombre]', item.name)
        for (const [key1, item1] of Object.entries(item.list)) {
          formData.append(`Carpetas[${key}][CarpetasDocumentos][${key1}][documentoId]`, item1.id)
        }
      }

      _LicitacionesService.value.saveLicitaciones(formData).then(({ data }) => {
        if (data.success) {
          Swal.fire({
            title: 'Exito',
            text: 'La licitacion se guardo correctamente',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          }).then((result) => {
            router.push({ name: 'licitaciones.ver' })
          })
        }
      })
      resetForm()
    }

    const onUpload = async (event) => {
      for (let i = 0; i < event.files.length; i++) {
        formData.append('adjuntoPliegos', event.files[i])
      }
    }

    const resetForm = () => {
      state.nombre = ''
      state.fechaApertura = ''
      state.fechaCierre = ''
      state.comentario = ''
      state.lugarEjecucion = ''
      state.tipoRadicacion = ''
      state.valorPresupuesto = 0
      submitted.value = false
      formData = new FormData()
    }

    const onColReorder = () => {
      // toast.add({ severity: 'success', summary: 'Column Reordered', life: 3000 })
    }
    const onRowReorder = (event) => {
      carpetas.value = event.value
      // toast.add({ severity: 'success', summary: 'Rows Reordered', life: 3000 })
    }
    const abrio = (event) => {
      document.getElementById(`myAcordion_${event.index}`).classList.add('pi-folder-open')
      document.getElementById(`myAcordion_${event.index}`).classList.remove('pi-folder')
    }

    const cerro = (event) => {
      document.getElementById(`myAcordion_${event.index}`).classList.add('pi-folder')
      document.getElementById(`myAcordion_${event.index}`).classList.remove('pi-folder-open')
    }

    const actionModalNameFolder = (data) => {
      modalNameFolder.value = !modalNameFolder.value
      if (modalNameFolder.value) {
        updateCarpeta.value.nombre = data.name
        updateCarpeta.value.oldValue = data.name
      }
    }

    const editarCarpeta = () => {
      const posicion = carpetas.value.map((e) => { return e.name }).indexOf(updateCarpeta.value.oldValue)
      carpetas.value[posicion].name = updateCarpeta.value.nombre
      modalNameFolder.value = false
    }

    const eliminarCarpeta = (data) => {
      const posicion = carpetas.value.map((e) => { return e.name }).indexOf(data.name)
      carpetas.value.splice(posicion, 1)
    }

    return {
      state,
      v$,
      handleSubmit,
      submitted,
      countries,
      date,
      country,
      columns,
      carpetas,
      onColReorder,
      onRowReorder,
      modalAddFolder,
      log: (event) => {
        console.log(event)
      },
      documents,
      nameFolder,
      hideModalAddFolder,
      saveModalAddFolder,
      validationInput,
      tipoRadicaciones,
      onUpload,
      filterDocuments,
      filterText,
      documentsFiltered,
      abrio,
      cerro,
      actionModalNameFolder,
      modalNameFolder,
      updateCarpeta,
      editarCarpeta,
      eliminarCarpeta
    }
  }
}
</script>
<style lang="scss" scoped>
.form-demo {
  .card {
    min-width: 450px;

    form {
      margin-top: 2rem;
    }

    .field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  ::v-deep(.p-accordion .p-accordion-content) {
    background: #cfd4d703;
  }
}
</style>
